import React from 'react';
import './styles.scss'
import {StaticImage, GatsbyImage, getImage} from "gatsby-plugin-image";
import {Blog} from "../../../interfaces/Blog";
import {Link} from "gatsby";
import {LocaleType} from "../../../interfaces/Locale";
export default function Blogs(props:{blogs:Blog[],locale:LocaleType,lang:string}){
    let blogs:Blog[]=props.blogs;
    blogs=blogs.map(b=>{
        const blog={...b};
        blog.compiledFeaturedImage=getImage(blog.featuredImage.localFile);
        return blog;
    }).slice(0,3);
    return <div className="blogs-container">
        <div className="title">{props.locale["Home.Blogs.title"]}</div>
        <div className="underline"/>
        <div className="blogs">
            {blogs.map(blog=><div key={blog.title} className="blog">
                <div className="image">
                    {blog.compiledFeaturedImage&&<GatsbyImage image={blog.compiledFeaturedImage} alt={blog.title}/>}
                </div>
                <div className="title">
                    {blog.title}
                </div>
                <div className="desc">
                    {blog.description}
                </div>
                <div className="bottom">
                    <div className="date">
                        {blog.publishedDate||blog.publishedAt}
                    </div>
                    <Link to={(props.lang==="en"?"":`/${props.lang}`)+"/blog/"+blog.slug}>
                        <div className="read">
                            <StaticImage placeholder="blurred" src="../../../images/arrow_right.png" alt="read more"/>
                        </div>
                    </Link>

                </div>
            </div>)}
        </div>
        <div className="more">
            <Link to={(props.lang==="en"?"":`/${props.lang}`)+"/blogs"}>
                {props.locale["Home.Blogs.more"]} <StaticImage placeholder="blurred" src="../../../images/arrow_right.png" alt="read more"/>
            </Link>

        </div>
        <StaticImage placeholder="blurred" className="pattern" src="../../../images/dot_pattern2.png" alt="Dot pattern"/>
    </div>
}