import React from "react";
import './styles.scss'
import {StaticImage} from "gatsby-plugin-image";
import {LocaleType} from "../../../interfaces/Locale";

export default function Tests({locale}:{locale:LocaleType}) {
    return <div className="test-container">
        <div className="elements">
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.desc1']}

<br/>
<br/>
                        {locale['Home.Tests.desc2']}

                    </div>
                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.faq.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        <b>{locale["Home.Tests.faq1.q"]}</b>
                        <br/>
                        {locale["Home.Tests.faq1.a"]}
                        <br/>
                        <br/>
                        <b>{locale["Home.Tests.faq2.q"]}</b>
                        <br/>
                        {locale["Home.Tests.faq2.a"]}
                        <br/>
                        <br/>
                        <b>{locale["Home.Tests.faq3.q"]}</b>
                        <br/>
                        {locale["Home.Tests.faq3.a"]}
                        <br/>
                        <br/>
                        <b>{locale["Home.Tests.faq4.q"]}</b>
                        <br/>
                        {locale["Home.Tests.faq4.a"]}
                        <br/>
                        <br/>
                        <b>{locale["Home.Tests.faq5.q"]}</b>
                        <br/>
                        {locale["Home.Tests.faq5.a"]}
                        <br/>
                        <br/>
                        <b>{locale["Home.Tests.faq6.q"]}</b>
                        <br/>
                        {locale["Home.Tests.faq6.a"]}
                        <br/>
                        <br/>

                    </div>
                </div>
            </div>
        </div>
        <div className="pattern">
            <StaticImage placeholder="blurred" src="../../../images/dot_pattern3.png" alt="pattern"/>
        </div>
    </div>
}