import React from 'react';
import './styles.scss'
import {LocaleType} from "../../../interfaces/Locale";
export default function Footer({locale}:{locale:LocaleType}){
    return <div className="footer-container">
    <div className="elements">
        <div className="element">
                <div className="title">{locale["Home.Footer.dumpsTitle"]}</div>
                <a className="text" href="https://gedtestonline.us" target="_blank">GED Test Online</a>
                <a className="text" href="https://wguonlinedegree.us" target="_blank">WGU Online Degree</a>
                <a className="text" href="https://cbtproxy.com" target="_blank">Proxy Exam</a>
            </div>
            <div className="element">
            <div className="title"><a href="https://wa.me/13474801260" target="_blank"><div>{locale["Home.Footer.disclaimerTitle"]}</div></a><a href="mailto:info@examremote.com" target="_blank"><div>info@examremote.com</div></a></div>
            <div className="text">{locale["Home.Footer.disclaimer"]}</div>
         </div>
            <div className="element">
                <div className="title">{locale["Home.Footer.websitesTitle"]}</div>
                <a className="text" href="https://cisspexamonline.com/" target="_blank">CISSP Exam Online</a>
                <a className="text" href="https://pmpexam.co" target="_blank">PMP Exam Online</a>
                <a className="text" href="https://ccierecertification.com" target="_blank">CCIE Recertification</a>
                
        </div>
        </div>
        </div>
}
